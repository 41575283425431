import "./Beta.scss"
import React from "react";
import {Dot} from "../../atoms/Dot/Dot";
import {Title} from "../../atoms/Title/Title";

export const Beta = (): React.JSX.Element => {

    const items = [
        {
            dotValue: 1,
            title: 'Kontakt s obchodím zástupcem',
            description: 'V první fázi proběhne komunikace s naším obchodním zástupcem, který vám poskytne veškeré potřebné informace o projektu.'
        },
        {
            dotValue: 2,
            title: 'Konzultace s personálem',
            description: 'Následně bude potřeba získat informace od vás, aby vše mohlo být naplánováno přesně na míru podle vašich požadavků.'
        },
        {
            dotValue: 3,
            title: 'Instalace HW zařízení',
            description: 'Před spuštěním zkušebního provozu bude nezbytné nainstalovat HW zařízení, ' +
                'a to konkrétně jednotlivá čidla v rámci předem domluvených prostor.'
        },
        {
            dotValue: 4,
            title: 'Pilotní provoz',
            description: 'Po nainstalování HW zařízení a nastavení webového rozhraní dojde ke spuštění pilotního provozu.'
        },
        {
            dotValue: 5,
            title: 'Vyhodnocení',
            description: 'Na závěr proběhne zpětné zhodnocení jednotlivých aspektů projektu a zpětná vazba bude zapracována do dalšího vývoje.'
        }
    ];

    return (
        <div id="Beta" className={'Beta'}>
            <div className={'Beta-content'}>
                <div className={'Beta-content-title'}>
                    <Title
                        text={'Co zahrnuje'}
                        accent={'pilotní provoz'}
                        suffix={'?'}
                        description={
                            'Celý proces pilotního provozu se bude skládat z pěti kroků.'
                        }
                    />
                </div>
                <div className={'Beta-content-inside'}>
                    {items.map((item, index: number) => {
                        return (
                            <div className={'Beta-content-inside-item'} key={`Beta-content-inside-item-${index}`}>
                                <div className={'Beta-content-inside-item-first'}>
                                    <div className={'Beta-content-inside-item-first-left'}>
                                        <Dot title={item.dotValue.toString()} />
                                    </div>
                                    <div className={'Beta-content-inside-item-first-right'}>
                                        {item.title}
                                    </div>
                                </div>
                                <div className={'Beta-content-inside-item-second'}>
                                    <div className={'Beta-content-inside-item-second-left'}>
                                        {index < items.length - 1 &&
                                            <div className={'Beta-content-inside-item-second-left-separator'}>
                                            </div>
                                        }
                                    </div>
                                    <div className={'Beta-content-inside-item-second-right'}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
