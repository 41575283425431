import {Helmet, HelmetProvider} from "react-helmet-async";
import {Navigation} from "../molecules/Navigation/Navigation";
import "./MainTemplate.scss"
import {Intro} from "../molecules/Intro/Intro";
import {HowItWork} from "../molecules/HowItWork/HowItWork";
import {Beta} from "../molecules/Beta/Beta";
import {Gallery} from "../molecules/Gallery/Gallery";
import {Contact} from "../molecules/Contact/Contact";
import {Footer} from "../molecules/Footer/Footer";
import {Targets} from "../organisms/Targets/Targets";
import {Pilot} from "../molecules/Pilot/Pilot";
import {Security} from "../molecules/Security/Security";
import {Future} from "../organisms/Future/Future";
import React from "react";

export const MainTemplate = (): React.JSX.Element => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Angelo</title>
                <meta charSet="utf-8" />
            </Helmet>
            <div className={'MainTemplate'}>
                <header>
                    <Navigation />
                </header>
                <main>
                    <Intro />
                    <HowItWork />
                    <Beta />
                    <Targets />
                    <Pilot />
                    <Security />
                    <Future />
                    <Gallery />
                    <Contact />
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>
        </HelmetProvider>
    )
}
