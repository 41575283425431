import "./CustomButton.scss"
import React from "react";

export enum CustomButtonType {
    PRIMARY = "primary",
    ACCENT = "accent"
}

type CustomButtonProps = {
    title: string,
    type: CustomButtonType,
}

export const CustomButton = ({title, type} : CustomButtonProps): React.JSX.Element => {

    return (
        <div className={`CustomButton ${type}`}>
            <button>{title}</button>
        </div>
    )
}
