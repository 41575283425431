import "./Title.scss"
import React from "react";

type TitleProps = {
    text: string,
    accent?: string,
    suffix?: string,
    description?: string,
    descriptionAccentSuffix?: string,
    plusIcon?: boolean,
}

export const Title = ({text, accent, description, plusIcon, suffix, descriptionAccentSuffix} : TitleProps): React.JSX.Element => {

    return (
        <div className={'Title'}>
            <div className={'Title-main'}>
                {plusIcon &&
                    <div className={'Title-main-plus'}>
                        +
                    </div>
                }
                <div className={'Title-main-text'}>
                    {text}
                </div>
                {accent &&
                    <div className={'Title-main-accent'}>
                        &nbsp;{accent}
                    </div>
                }
                {suffix &&
                    <div className={'Title-main-text'}>
                        {suffix}
                    </div>
                }
            </div>
            {description &&
                <div className={'Title-description'}>
                    {description}
                    {descriptionAccentSuffix &&
                        <span className={'accent'}>&nbsp;{descriptionAccentSuffix}</span>
                    }
                </div>
            }
        </div>
    )
}
