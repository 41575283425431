import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {MainPage} from "./components/pages/MainPage";
import {checkAvifSupport} from "./utils/avifSupport";

function App() {

    checkAvifSupport();
    return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage/>} />
        </Routes>
      </Router>
    );
}

export default App;
