import "./Switch.scss"
import React from "react";

type SwitchProps = {
    items: Array<{
        iconActive: any,
        iconInactive: any,
        title: string,
        active: boolean,
        id: number,
        callBack: () => void,
    }>
}

export const Switch = ({items} : SwitchProps): React.JSX.Element => {

    return (
        <div className={'Switch'}>
            <div className={'Switch-content'}>
                {items.map((item, index: number) => {
                    return (
                        <div
                            onClick={ () => { item.callBack(); } }
                            className={`Switch-content-item ${item.active ? 'active' : 'inactive'}`}
                            key={`Switch-content-item-${index}`}>
                            <div className={'Switch-content-item-icon icon'}>
                                <img src={item.active ? item.iconActive : item.iconInactive} alt={item.title}/>
                            </div>
                            <div className={'Switch-content-item-text text'}>
                                {item.title}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
