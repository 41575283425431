import "./Dot.scss"
import React from "react";

type DotProps = {
    title: string
}

export const Dot = ({title} : DotProps): React.JSX.Element => {

    return (
        <div className={'Dot'}>
            <div className={'Dot-content'}>
                <div className={'Dot-content-title'}>
                    {title}
                </div>
            </div>
        </div>
    )
}
