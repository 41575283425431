import "./Future.scss"
import React from "react";
import {Title} from "../../atoms/Title/Title";
import {BulletList} from "../../molecules/BulletList/BulletList";
import firstIcon from '../../../images/Future/first.svg';
import secondIcon from '../../../images/Future/second.svg';

export const Future = (): React.JSX.Element => {

    const items = [
        {
            icon: null,
            title: 'HOTOVÝ VÝVOJ',
            items: [
                'Monitoring fyzického stavu (teplota, kroky, tep)',
                'SOS tlačítko, Detekce pádu (automaticky)',
                'Stanovení polohy osoby',
                'Integrace monitoringu prostředí provozu (teplota, CO2, požár, vlhkost, ...)',
                'Mobilní a webová aplikace',
                'Komerční nasazení v ostrém provozu'
            ]
        }, {
            icon: null,
            title: 'Vývoj a cíle pro 2024',
            items: [
                'Rozšíření komunikace o další protokoly – Lora',
                'Další integrace HW dle požadavků zákazníků',
                'Integrace dohledového systému, kamerový monitoring',
                'Rozšíření webové a mobilní aplikace'
            ]
        }
    ];

    const items2 = [
        {
            icon: null,
            title: 'Aktuální stav: Prototyp',
            items: [
                'Rozpoznání až 8 lidí v místnosti plné dalších osob',
                'Monitoring skeletálních bodů osoby',
                'Statistiky pohyblivosti jednotlivých bodů'
            ]
        }
    ];

    return (
        <div id="Future" className={'Future'}>
            <div className={'Future-content'}>
                <div className={'Future-content-title'}>
                    <Title text={'Další vývoj'} />
                </div>
                <div className={'Future-content-inside'}>
                    <BulletList items={items} />
                </div>
                <div className={'Future-content-skeleton'}>
                    <div className={'Future-content-skeleton-inside'}>
                        <div className={'Future-content-skeleton-inside-title'}>
                            EXPERIMENTÁLNÍ VÝVOJ DETEKCE
                            POHYBU SKELETONU
                        </div>
                        <div className={'Future-content-skeleton-inside-desc'}>
                            Rozpoznání konkrétních osob v místnosti
                            a sledování jejich skeletálního pohybu
                        </div>
                    </div>
                </div>
                <div className={'BkgBlock'}>
                    <div className={'Future-content-bullet'}>
                        <BulletList items={items2} neutralTitleColor={true} />
                    </div>
                    <div className={'Future-content-gallery'}>
                        <div className={'Future-content-gallery-inside'}>
                            <div className={'Future-content-gallery-inside-item'}>
                                <img src={firstIcon} alt={'Obrázek kostry'}/>
                            </div>
                            <div className={'Future-content-gallery-inside-item'}>
                                <img src={secondIcon} alt={'Obrázek tváre'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
