import "./Pilot.scss"
import React from "react";
import {Title} from "../../atoms/Title/Title";
import firstIcon from '../../../images/Pilot/first.svg';
import secondIcon from '../../../images/Pilot/second.svg';
import thirdIcon from '../../../images/Pilot/third.svg';
import fourthIcon from '../../../images/Pilot/fourth.svg';
import fifthIcon from '../../../images/Pilot/fifth.svg';

import first1x from "../../../images/Pilot/Screens/1.png";
import first2x from "../../../images/Pilot/Screens/1@2x.png";
import first3x from "../../../images/Pilot/Screens/1@3x.png";
import first1xA from "../../../images/Pilot/Screens/1.avif";
import first2xA from "../../../images/Pilot/Screens/1@2x.avif";
import first3xA from "../../../images/Pilot/Screens/1@3x.avif";

import second1x from "../../../images/Pilot/Screens/2.png";
import second2x from "../../../images/Pilot/Screens/2@2x.png";
import second3x from "../../../images/Pilot/Screens/2@3x.png";
import second1xA from "../../../images/Pilot/Screens/2.avif";
import second2xA from "../../../images/Pilot/Screens/2@3x.avif";
import second3xA from "../../../images/Pilot/Screens/2@3x.avif";

import third1x from "../../../images/Pilot/Screens/3.png";
import third2x from "../../../images/Pilot/Screens/3@2x.png";
import third3x from "../../../images/Pilot/Screens/3@3x.png";
import third1xA from "../../../images/Pilot/Screens/3.avif";
import third2xA from "../../../images/Pilot/Screens/3@2x.avif";
import third3xA from "../../../images/Pilot/Screens/3@3x.avif";

import arrow1 from '../../../images/Pilot/Screens/arrow1.svg';
import arrow2 from '../../../images/Pilot/Screens/arrow2.svg';


export const Pilot = (): React.JSX.Element => {

    const items = [
        {
            icon: firstIcon,
            title: 'Prohlídka místa implementace',
            description: 'Vyhodnocení specifik provozu. Členitosti místností, typ a šířka zdí, počet pater, tvar budovy atd.'
        }, {
            icon: secondIcon,
            title: 'Konzultace s personálem',
            description: 'Představení aplikace Angelo personálu v provozu včetně úvodního zaškolení.'
        }, {
            icon: thirdIcon,
            title: 'Rozmístění hw, aktivace aplikace',
            description: 'Vytvoření uživatelských účtů do aplikace Angelo. Zprovoznění a rozmístění veškerého HW po provozu.'
        },  {
            icon: fourthIcon,
            title: 'Testovací provoz I & zpětná vazba',
            description: 'Angelo bude po domluvenou dobu používáno v reálném provozu. Řešení připomínek a návrhů k úpravám.'
        },  {
            icon: fifthIcon,
            title: 'Testovací provoz II',
            description: 'Nasazení další verze Angelo upravené dle podnětů personálu.'
        },
    ];

    return (
        <div id="Pilot" className={'Pilot'}>
            <div className={'Pilot-content'}>
                <div className={'Pilot-content-left'}>
                    <div className={'Pilot-content-left-title'}>
                        <Title
                            text={'Pilotní'}
                            accent={'provoz'}
                            description={'Celý proces pilotního testování v provozu se bude skládat z pěti kroků.'}
                        />
                    </div>
                    <div className={'Pilot-content-left-inside'}>
                    {items.map((item, index: number) => {
                        return (
                            <div className={'Pilot-content-left-inside-item'} key={`Pilot-content-left-inside-item-${index}`}>
                                <div className={'Pilot-content-left-inside-item-left'}>
                                    <div className={'Pilot-content-left-inside-item-left-image'}>
                                        <img src={item.icon} alt={'Ikona'} />
                                    </div>
                                    <div className={'Pilot-content-left-inside-item-left-separator separator'}>
                                        <div className={'Pilot-content-left-inside-item-left-separator-item'}>
                                        </div>
                                    </div>
                                </div>
                                <div className={'Pilot-content-left-inside-item-right'}>
                                    <div className={'Pilot-content-left-inside-item-right-title'}>
                                        {item.title}
                                    </div>
                                    <div className={'Pilot-content-left-inside-item-right-desc'}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
                </div>
                <div className={'Pilot-content-right'}>
                    <div className={'Pilot-content-right-screens'}>
                <div className={'Pilot-content-right-screens-screen'}>
                    <picture>
                        <source srcSet={`${first1xA} 476w, ${first2xA} 952w, ${first3xA} 1428w`} type="image/avif"/>
                        <source srcSet={`${first1x} 476w, ${first2x} 952w, ${first3x} 1428w`} type="image/png"/>
                        <img src={first1x} alt={'Dashboard'} />
                    </picture>
                </div>
                <div className={'Pilot-content-right-screens-arrow'}>
                    <img src={arrow1} alt={"Šípka"} />
                </div>
                <div className={'Pilot-content-right-screens-screen'}>
                    <picture>
                        <source srcSet={`${second1xA} 476w, ${second2xA} 952w, ${second3xA} 1428w`} type="image/avif"/>
                        <source srcSet={`${second1x} 476w, ${second2x} 952w, ${second3x} 1428w`} type="image/png"/>
                        <img src={second1x} alt={'Dashboard'} />
                    </picture>
                </div>
                <div className={'Pilot-content-right-screens-arrow'}>
                    <img src={arrow2} alt={"Šípka"} />
                </div>
                <div className={'Pilot-content-right-screens-screen'}>
                    <picture>
                        <source srcSet={`${third1xA} 476w, ${third2xA} 952w, ${third3xA} 1428w`} type="image/avif"/>
                        <source srcSet={`${third1x} 476w, ${third2x} 952w, ${third3x} 1428w`} type="image/png"/>
                        <img src={third1x} alt={'Dashboard'} />
                    </picture>
                </div>
            </div>
                </div>
        </div>
    </div>
)}
