import "./Contact.scss"
import React from "react";
import po1x from '../../../images/ProductOwner/PO.png';
import po2x from '../../../images/ProductOwner/PO@2x.png';
import po3x from '../../../images/ProductOwner/PO@3x.png';
import po1xA from '../../../images/ProductOwner/PO.avif';
import po2xA from '../../../images/ProductOwner/PO@2x.avif';
import po3xA from '../../../images/ProductOwner/PO@3x.avif';
import address from '../../../images/ProductOwner/address.svg';
import phone from '../../../images/ProductOwner/phone.svg';
import mail from '../../../images/ProductOwner/mail.svg';

import {CustomButton, CustomButtonType} from "../../atoms/CustomButton/CustomButton";

export const Contact = (): React.JSX.Element => {

    return (
        <div id="Contact" className={'Contact'}>
            <div className={'Contact-content'}>
                <div className={'Contact-content-left'}>
                    <picture>
                        <source srcSet={`${po1xA} 493w, ${po2xA} 985w, ${po3xA} 1478w`} type="image/avif"/>
                        <source srcSet={`${po1x} 493w, ${po2x} 985w, ${po3x} 1478w`} type="image/png"/>
                        <img src={po1x} alt={'Product owner'}/>
                    </picture>
                </div>
                <div className={'Contact-content-right'}>
                    <div className={'Contact-content-right-name'}>
                        Ing. Frederik Gergeľ
                    </div>
                    <div className={'Contact-content-right-position'}>
                        Product owner
                    </div>
                    <div className={'Contact-content-right-description'}>
                       <div className={'Contact-content-right-description-item'}>
                           <div className={'Contact-content-right-description-item-label'}>
                               <img src={phone} alt={'Phone number'} />
                           </div>
                           <div className={'Contact-content-right-description-item-text'}>
                               +420 778 112 988
                           </div>
                       </div>
                        <div className={'Contact-content-right-description-item'}>
                            <div className={'Contact-content-right-description-item-label'}>
                                <img src={mail} alt={'E-mail'} />
                            </div>
                            <div className={'Contact-content-right-description-item-text'}>
                                gergel@master.cz
                            </div>
                        </div>
                        <div className={'Contact-content-right-description-item'}>
                            <div className={'Contact-content-right-description-item-label'}>
                                <img src={address} alt={'Address'} />
                            </div>
                            <div className={'Contact-content-right-description-item-text'}>
                                Purkyňova 35e, Brno - Královo Pole
                            </div>
                        </div>
                    </div>
                    <a className={'Contact-content-right-button'} href={'mailto:gergel@master.cz'}>
                        <CustomButton title={'Kontaktujte mě'} type={CustomButtonType.PRIMARY} />
                    </a>
                </div>
            </div>
        </div>
    )
}
