import "./Navigation.scss"
import React from "react";
import logo from "../../../images/logo.svg";
import menu from "../../../images/menu.svg";
import cross from "../../../images/menu.svg";
import {CustomButton, CustomButtonType} from "../../atoms/CustomButton/CustomButton";

export const Navigation = (): React.JSX.Element => {

    const [opened, setOpened] = React.useState<boolean>(false);

    return (
        <div className={`Navigation ${opened ? 'opened' : ''}`}>
            <div className={'Navigation-inside'}>
                <div className={'Navigation-inside-content'}>
                <div className={'Navigation-inside-content-left'}>
                    <div className={'Navigation-inside-content-left-logo'}>
                        <div className={'Navigation-inside-content-left-logo-icon'}>
                            <img src={logo} alt={'Brand icon'} />
                        </div>
                        <div className={'Navigation-inside-content-left-logo-brand'}>
                            ANGELO
                        </div>
                    </div>
                </div>
                <div className={'Navigation-inside-content-center'}>
                    <a href={'#HowItWork'} className={'Navigation-inside-content-center-item item'}>
                        Jak to funguje?
                    </a>
                    <a href={'#Beta'} className={'Navigation-inside-content-center-item item'}>
                        Pilotní provoz
                    </a>
                    <a href={'#Gallery'} className={'Navigation-inside-content-center-item item'}>
                        Galerie
                    </a>
                    <a href={'#Contact'} className={'Navigation-inside-content-center-item item'}>
                        Kontakt
                    </a>
                </div>
                <div className={'Navigation-inside-content-right'}>
                    <a href={'#Contact'} className={'Navigation-inside-content-right-item'}>
                        <CustomButton title={'Kontakt'} type={CustomButtonType.PRIMARY} />
                    </a>
                </div>
                <div className={'Navigation-inside-content-rightMobile'}>
                    <img
                        onClick={ () => { setOpened(!opened); } }
                        src={opened ? cross : menu}
                        alt={'Mobile menu'} />
                </div>
            </div>
                <div className={`Navigation-inside-popup ${opened ? 'opened' : ''}`}>
                    <div className={'Navigation-inside-popup-inside'}>
                        <div
                            onClick={ () => { setOpened(false); } }
                            className={'Navigation-inside-popup-inside-item'}>
                            <a href={'#HowItWork'} className={'Navigation-inside-popup-inside-item-element'}>
                                Jak to funguje?
                            </a>
                        </div>
                        <div
                            onClick={ () => { setOpened(false); } }
                            className={'Navigation-inside-popup-inside-item'}>
                            <a href={'#Beta'} className={'Navigation-inside-popup-inside-item-element'}>
                                Pilotní provoz
                            </a>
                        </div>
                        <div
                            onClick={ () => { setOpened(false); } }
                            className={'Navigation-inside-popup-inside-item'}>
                            <a href={'#Gallery'} className={'Navigation-inside-popup-inside-item-element'}>
                                Galerie
                            </a>
                        </div>
                        <div
                            onClick={ () => { setOpened(false); } }
                            className={'Navigation-inside-popup-inside-item'}>
                            <a href={'#Contact'} className={'Navigation-inside-popup-inside-item-element'}>
                                Kontakt
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
