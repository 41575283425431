import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/layout.scss';
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router-dom";

if (process.env.REACT_APP_ENV === "production") {

    Sentry.init({
        dsn: "https://87840b35057e4869a59bf4c17e93e16d@o381139.ingest.sentry.io/6683810",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            })
        ],
        environment: process.env.REACT_APP_ENV,
        release: `grant-angelo@${process.env.REACT_APP_VERSION}`,
        tracesSampleRate: 0.1,
        normalizeDepth: 10,
        initialScope: {}
    })
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
