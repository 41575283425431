import "./Intro.scss"
import React from "react";
import {CustomButton, CustomButtonType} from "../../atoms/CustomButton/CustomButton";
import pros from "../../../images/pros.svg";

export const Intro = (): React.JSX.Element => {

    return (
        <div className={'Intro'}>
            <div className={'Intro-content'}>
                <div className={'Intro-content-left'}>
                    <div className={'Intro-content-left-title'}>
                        Vytváříme IoT ekosystém
                    </div>
                    <div className={'Intro-content-left-subTitle'}>
                        pro zdravotnictví a pečovatelství
                    </div>
                    <div className={'Intro-content-left-description'}>
                        Naším cílem je pomocí komplexního software zpřehlednit
                        stav uživatelů v zařízeních.
                    </div>
                    <div className={'Intro-content-left-pros'}>
                        <div className={'Intro-content-left-pros-item'}>
                            <div className={'Intro-content-left-pros-item-icon'}>
                                <img src={pros} alt={'Plus ikona'}/>
                            </div>
                            <div className={'Intro-content-left-pros-item-text'}>
                                Zabezpečený IoT ekosystém řízený z centrální webové aplikace
                            </div>
                        </div>
                        <div className={'Intro-content-left-pros-item'}>
                            <div className={'Intro-content-left-pros-item-icon'}>
                                <img src={pros} alt={'Plus ikona'}/>
                            </div>
                            <div className={'Intro-content-left-pros-item-text'}>
                                Monitoring zdravotního stavu osob umožňující včasný zásah v případě alarmní události
                            </div>
                        </div>
                        <div className={'Intro-content-left-pros-item'}>
                            <div className={'Intro-content-left-pros-item-icon'}>
                                <img src={pros} alt={'Plus ikona'}/>
                            </div>
                            <div className={'Intro-content-left-pros-item-text'}>
                                Možná integrace většiny dostupných IoT zařízení na trhu
                            </div>
                        </div>
                    </div>
                    <a href={'#HowItWork'} className={'Intro-content-left-button'}>
                        <CustomButton title={'Náhled aplikace'} type={CustomButtonType.ACCENT} />
                    </a>
                </div>
                <div className={'Intro-content-right'}>
                    <div className={'Intro-content-right-screen'}>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/EhhwBkpi7wA?si=RXhNtdQ79FQj9I9w&amp;controls=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <a href={'#HowItWork'} className={'Intro-content-buttonMobile'}>
                    <CustomButton title={'Náhled aplikace'} type={CustomButtonType.ACCENT}/>
                </a>
            </div>
        </div>
    )
}
