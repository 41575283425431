import React, {useEffect} from "react";

export const useOutsideClickTrigger = (ref: Array<React.MutableRefObject<any>>, callback: () => void) => {

    useEffect(() => {

        function handleClickOutside(event: Event) {
            let setUp = true;
            ref.forEach((item: React.MutableRefObject<any>) => {
                console.log(item.current);
                if (!(item.current && !item.current.contains(event.target))) {
                    setUp = false;
                }
            })
            if (setUp) { callback(); }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside); };
    }, [ref, callback]);
};
