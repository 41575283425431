import "./Security.scss"
import React from "react";
import {Title} from "../../atoms/Title/Title";
import firstIcon from '../../../images/Security/first.svg';
import secondIcon from '../../../images/Security/second.svg';
import thirdIcon from '../../../images/Security/third.svg';

export const Security = (): React.JSX.Element => {

    const items = [
        {
            icon: firstIcon,
            description: 'Bezpečný transfer a ukládání dat na mobilním zařízení',
        }, {
            icon: secondIcon,
            description: 'Private keys uložené na HSM',
        }, {
            icon: thirdIcon,
            description: 'SSL / TLS autorizace'
        },
    ];

    return (
        <div id="Security" className={'Security'}>
            <div className={'Security-content'}>
                <div className={'Security-content-title'}>
                    <Title
                        text={'Security'}
                        description={'Splňujeme normy'}
                        descriptionAccentSuffix={'X509, C-ITSRSA, ECC'}
                    />
                </div>
                <div className={'Security-content-inside'}>
                    {items.map((item, index: number) => {
                        return (
                            <div className={'Security-content-inside-item'} key={`Security-content-inside-item-${index}`}>
                                <div className={'Security-content-inside-item-in'}>
                                    <div className={'Security-content-inside-item-in-icon'}>
                                        <div className={'Security-content-inside-item-in-icon-element'}>
                                            <img src={item.icon} alt={'Ikona'} />
                                        </div>
                                    </div>
                                    <div className={'Security-content-inside-item-in-text'}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
