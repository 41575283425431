import "./BulletList.scss"
import React from "react";
import pros from "../../../images/prosBlue.svg";

type BulletListProps = {
    items: Array<{
        icon: any|null,
        title: string,
        items: string[]
    }>,
    neutralTitleColor?: boolean,
}

export const BulletList = ({items, neutralTitleColor} : BulletListProps): React.JSX.Element => {

    return (
        <div id="BulletList" className={'BulletList'}>
            <div className={'BulletList-content'}>
                <div className={'BulletList-content-inside'}>
                    {items.map((item, index: number) => {
                        return (
                            <div className={'BulletList-content-inside-item'} key={`BulletList-content-inside-item-${index}`}>
                                {item.icon &&
                                    <div className={'BulletList-content-inside-item-image'}>
                                        <img src={item.icon} alt={item.title} />
                                    </div>
                                }
                                <div className={`BulletList-content-inside-item-title title ${neutralTitleColor ? 'neutral' : ''}`}>
                                    {item.title}
                                </div>
                                <div className={'BulletList-content-inside-item-list'}>
                                    {item.items.map((listItem, listIndex: number) => {
                                        return (
                                            <div className={'BulletList-content-inside-item-list-item'}
                                                 key={`BulletList-content-inside-item-list-item-${listIndex}`}>
                                                <div className={'BulletList-content-inside-item-list-item-icon'}>
                                                    <img src={pros} alt={'Plus ikona'} />
                                                </div>
                                                <div className={'BulletList-content-inside-item-list-item-text'}>
                                                    {listItem}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
