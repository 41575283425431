import "./Targets.scss"
import React from "react";
import {Title} from "../../atoms/Title/Title";
import firstIcon from '../../../images/Targets/1.svg';
import secondIcon from '../../../images/Targets/2.svg';
import logoIcon from '../../../images/logo.svg';
import leftArrow from "../../../images/Targets/Circle/leftArrow.svg";
import rightArrow from "../../../images/Targets/Circle/rightArrow.svg";
import centerArrow from "../../../images/Targets/Circle/centerArrow.svg";
import doubleHouse from "../../../images/Targets/Circle/doubleHouse.svg";
import redHouse from "../../../images/Targets/Circle/redHouse.svg";
import factory from "../../../images/Targets/Circle/factory.svg";
import {BulletList} from "../../molecules/BulletList/BulletList";

export const Targets = (): React.JSX.Element => {

    const items = [
        {
            icon: firstIcon,
            title: 'Zdravotnictví a pečovatelství',
            items: [
                'Domy s pečovatelskou službou',
                'Centra denních služeb',
                'Centrum soc. rehabilitačních služeb',
                'Domov se zvláštním režimem',
                'Domovy pro osoby se zdravotním postižením'
            ]
        }, {
            icon: secondIcon,
            title: 'Chráněné dílny a sociální podniky',
            items: [
                'Chráněné dílny',
                'Podniky se zvýšeným počtem osob znevýhodněných na trhu práce',
                'Provozy na detekci pádu a zjištění polohy osoby'
            ]
        }, {
            icon: null,
            title: 'Průmysl & logistika',
            items: [
                'Průmyslové provozy s potřebou monitoringu fyzického stavu osob',
                'Riziková pracoviště v důlním a hutním průmyslu',
                'Sklady a logistická centra s četným pohybem personálu',
                'Provozy s nárokem na monitoring stavu prostředí',
                'Zemědělské provozy a živočišná výroba'
            ]
        },
    ];

    return (
        <div id="Targets" className={'Targets'}>
            <div className={'Targets-content'}>
                <div className={'Targets-content-logo'}>
                    <img src={logoIcon} alt={'Logo'} />
                </div>
                <div className={'Targets-content-title'}>
                    <Title
                        text={'Cílové'}
                        accent={'provozy'}
                        description={'Provozy se zvýšeným nárokem na monitoring fyzického stavu osob'}
                    />
                </div>
                <div className={'Targets-content-inside'}>
                    <BulletList items={items} />
                </div>
                <div className={'Targets-content-graphic'}>
                    <div className={'Targets-content-graphic-text'}>
                        Angelo
                    </div>
                    <div className={'Targets-content-graphic-inside'}>
                        <div className={'Targets-content-graphic-inside-firstLine'}>
                            <img src={leftArrow} alt={'Šípka'} />
                            <img src={doubleHouse} alt={'Dom'} />
                            <img src={rightArrow} alt={'Šípka'} />
                        </div>
                        <div className={'Targets-content-graphic-inside-secondLine'}>
                            <img src={factory} alt={'Firma'} />
                            <img src={centerArrow} alt={'Šípka'} />
                            <img src={redHouse} alt={'Dom'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
