import "./HowItWork.scss"
import React from "react";
import scheme1x from '../../../images/Schema/schema@1x.png';
import scheme2x from '../../../images/Schema/schema@2x.png';
import scheme3x from '../../../images/Schema/schema@3x.png';
import scheme1xA from '../../../images/Schema/schema@1x.avif';
import scheme2xA from '../../../images/Schema/schema@2x.avif';
import scheme3xA from '../../../images/Schema/schema@3x.avif';
import schemeTechnic1x from '../../../images/Schema/schemaTechnik@1x.png';
import schemeTechnic2x from '../../../images/Schema/schemaTechnik@2x.png';
import schemeTechnic3x from '../../../images/Schema/schemaTechnik@3x.png';
import schemeTechnic1xA from '../../../images/Schema/schemaTechnik@1x.avif';
import schemeTechnic2xA from '../../../images/Schema/schemaTechnik@2x.avif';
import schemeTechnic3xA from '../../../images/Schema/schemaTechnik@3x.avif';
import technicalActive from '../../../images/technicalActive.svg';
import technicalInactive from '../../../images/technicalInacitve.svg';
import medicalActive from '../../../images/medicalActive.svg';
import medicalInactive from '../../../images/medicalInactive.svg';
import down from '../../../images/down.svg';
import up from '../../../images/up.svg';
import fingerPrint from '../../../images/Schema/fingerPrint.svg';
import diagnose from '../../../images/Schema/diagnose.svg';
import identity from '../../../images/Schema/identity.svg';
import beat from '../../../images/Schema/beat.svg';
import fall from '../../../images/Schema/fall.svg';
import monitor from '../../../images/Schema/monitor.svg';
import hearth from '../../../images/Schema/hearth.svg';
import med1 from '../../../images/Schema/med1.svg';
import med41x from '../../../images/Schema/med4.png';
import med42x from '../../../images/Schema/med4@2x.png';
import med43x from '../../../images/Schema/med4@3x.png';
import med41xA from '../../../images/Schema/med4.avif';
import med42xA from '../../../images/Schema/med4@2x.avif';
import med43xA from '../../../images/Schema/med4@3x.avif';
import med51x from '../../../images/Schema/med5.png';
import med52x from '../../../images/Schema/med5@2x.png';
import med53x from '../../../images/Schema/med5@3x.png';
import med51xA from '../../../images/Schema/med5.avif';
import med52xA from '../../../images/Schema/med5@2x.avif';
import med53xA from '../../../images/Schema/med5@3x.avif';
import med21x from '../../../images/Schema/med2.png';
import med22x from '../../../images/Schema/med2@2x.png';
import med23x from '../../../images/Schema/med2@3x.png';
import med21xA from '../../../images/Schema/med2.avif';
import med22xA from '../../../images/Schema/med2@2x.avif';
import med23xA from '../../../images/Schema/med2@3x.avif';
import med31x from '../../../images/Schema/med3.png';
import med32x from '../../../images/Schema/med3@2x.png';
import med33x from '../../../images/Schema/med3@3x.png';
import med31xA from '../../../images/Schema/med3.avif';
import med32xA from '../../../images/Schema/med3@2x.avif';
import med33xA from '../../../images/Schema/med3@3x.avif';
import tech31x from '../../../images/Schema/tech3.png';
import tech32x from '../../../images/Schema/tech3@2x.png';
import tech33x from '../../../images/Schema/tech3@3x.png';
import tech31xA from '../../../images/Schema/tech3.avif';
import tech32xA from '../../../images/Schema/tech3@2x.avif';
import tech33xA from '../../../images/Schema/tech3@3x.avif';
import tech21x from '../../../images/Schema/tech2.png';
import tech22x from '../../../images/Schema/tech2@2x.png';
import tech23x from '../../../images/Schema/tech2@3x.png';
import tech21xA from '../../../images/Schema/tech2.avif';
import tech22xA from '../../../images/Schema/tech2@2x.avif';
import tech23xA from '../../../images/Schema/tech2@3x.avif';
import tech11x from '../../../images/Schema/tech1.png';
import tech12x from '../../../images/Schema/tech1@2x.png';
import tech13x from '../../../images/Schema/tech1@3x.png';
import tech11xA from '../../../images/Schema/tech1.avif';
import tech12xA from '../../../images/Schema/tech1@2x.avif';
import tech13xA from '../../../images/Schema/tech1@3x.avif';

import {Switch} from "../../atoms/Switch/Switch";
import {Title} from "../../atoms/Title/Title";

export const HowItWork = (): React.JSX.Element => {

    const [switchType, setSwitchType] = React.useState<number>(1);

    const getDownElement = () : React.JSX.Element => {

      return (
          <div className={'HowItWork-content-picture-mobile-down'}>
              <div className={'HowItWork-content-picture-mobile-down-bkg'}>
                  <div className={'HowItWork-content-picture-mobile-down-bkg-arrow'}>
                      <img src={down} alt={'Down arrow'}/>
                  </div>
              </div>
          </div>
      )
    }

    const getBlock = (data: {items: Array<{icon: any, title: string, color: string, listItems: Array<string>}>}) : React.JSX.Element => {

        return (
            <div className={'HowItWork-content-picture-mobile-block'}>
                {data.items.map((item, index: number) => {
                    return (
                        <div key={`HowItWork-content-picture-mobile-block-item-${index}`}
                             className={'HowItWork-content-picture-mobile-block-item'}>
                            <div className={'HowItWork-content-picture-mobile-block-item-title'}>
                                <div className={`HowItWork-content-picture-mobile-block-item-title-image ${item.color}`}>
                                    <div className={'HowItWork-content-picture-mobile-block-item-title-image-item'}>
                                        <img src={item.icon} alt={'Title'} />
                                    </div>
                                </div>
                                <div className={'HowItWork-content-picture-mobile-block-item-title-text'}>
                                    {item.title}
                                </div>
                            </div>
                            <ul className={'HowItWork-content-picture-mobile-block-item-list'}>
                                {item.listItems.map((listItem, indexListItem: number) => {
                                    return (
                                        <li key={`HowItWork-content-picture-mobile-block-item-list-item-${indexListItem}`}
                                            className={'HowItWork-content-picture-mobile-block-item-list-item'}>
                                            {listItem}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        )
    }

    const block1MedicalData = {items: [
        {
            title: 'AI analýza fyzického stavu',
            icon: fingerPrint,
            color: 'orange',
            listItems: [
                'Snímání pohybu skeletonu',
                'Analýza pohybové aktivity',
                'Rozpoznání konkrétního pacienta podle konkrétního pacienta'
            ],
        },
    ]};

    const block1TechnicalData = {items: [
        {
            title: 'Monitoring pozadí',
            icon: monitor,
            color: 'orange',
            listItems: [
                'Teplota v místnosti',
                'Koncentrace CO2',
                'Vlhkost vzduchu'
            ],
        },
    ]};

    const block2TechnicalData = {items: [
            {
                title: 'Alarmní události zařízení',
                icon: beat,
                color: 'orange',
                listItems: [
                    'Požár',
                    'Neoprávněný přístup do budovy',
                ],
            },
        ]};

    const block2MedicalData = {items: [
        {
            title: 'Monitoring stavu pacienta',
            icon: hearth,
            color: 'green',
            listItems: ['Saturace kyslíku v krvi', 'Tělesná teplota', 'Tepová frekvence'],
        },
        {
            title: 'Detekce pádu',
            icon: fall,
            color: 'green',
            listItems: ['Hlášení alarmní události', 'Lokalizace pacienta v budově'],
        },
        {
            title: 'Záznamy léčby',
            icon: diagnose,
            color: 'green',
            listItems: ['Léky', 'Povinné procedury', 'Plán operací'],
        }
    ]};

    const block3TechnicalData = {items: [
        {
            title: 'Identifikace osoby',
            icon: identity,
            color: 'green',
            listItems: ['Identifikace osoby ve skupině', 'Face-ID udělení přístupue'],
        },
    ]};

    const getUpElement = () : React.JSX.Element => {
      return (
          <div className={'HowItWork-content-picture-mobile-down flip'}>
              <div className={'HowItWork-content-picture-mobile-down-bkg bkg'}>
                  <div className={'HowItWork-content-picture-mobile-down-bkg-arrow'}>
                      <img src={up} alt={'Down arrow'}/>
                  </div>
              </div>
          </div>
      )
    }

    return (
        <div className={'HowItWork-bkg'}>
            <div className={'HowItWork-bkg-in'}>
                <div id="HowItWork" className={'HowItWork'}>
            <div className={'HowItWork-content'}>
                <div className={'HowItWork-content-title'}>
                    <Title
                        plusIcon={true}
                        text={'Jak to'}
                        suffix={'?'}
                        accent={'funguje'}
                        description={
                            'Grafické znázornění toho, jak projekt bude fungovat v praxi, lze rozlišit \n' +
                            'z pohledu zdravotního personálu a z pohledu technika.'
                        } />
                </div>
                <div className={'HowItWork-content-switch'}>
                    <Switch items={[
                        {
                            iconActive: medicalActive,
                            iconInactive: medicalInactive,
                            title: 'Personál',
                            active: switchType === 1,
                            id: 1,
                            callBack: () => { setSwitchType(1);
                        }},
                        {
                            iconActive: technicalActive,
                            iconInactive: technicalInactive,
                            title: 'Technik',
                            active: switchType === 2,
                            id: 2,
                            callBack: () => { setSwitchType(2);
                        }}
                    ]} />
                </div>
                {switchType === 1 &&
                    <div className={'HowItWork-content-picture'}>
                        <div className={'HowItWork-content-picture-desktop medical'}>
                            <picture>
                                <source srcSet={`${scheme1xA} 937w, ${scheme2xA} 1873w, ${scheme3xA} 2810w`} type="image/avif"/>
                                <source srcSet={`${scheme1x} 937w, ${scheme2x} 1873w, ${scheme3x} 2810w`} type="image/png"/>
                                <img src={scheme1x} alt={'How it works screen'}/>
                            </picture>
                        </div>
                        <div className={'HowItWork-content-picture-mobile'}>
                            <div className={'HowItWork-content-picture-mobile-pic first'}>
                                <img src={med1} alt={'Patient'}/>
                            </div>
                            {getDownElement()}
                            <div className={'HowItWork-content-picture-mobile-pic second'}>
                                <picture>
                                    <source srcSet={`${med21xA} 410w, ${med22xA} 820w, ${med23xA} 1230w`} type="image/avif"/>
                                    <source srcSet={`${med21x} 410w, ${med22x} 820w, ${med23x} 1230w`} type="image/png"/>
                                    <img src={med21x} alt={'Camera'}/>
                                </picture>
                            </div>
                            {getDownElement()}
                            {getBlock(block1MedicalData)}
                            {getDownElement()}
                            <div className={'HowItWork-content-picture-mobile-pic third'}>
                                <picture>
                                    <source srcSet={`${med31xA} 493w, ${med32xA} 985w, ${med33xA} 1478w`} type="image/avif"/>
                                    <source srcSet={`${med31x} 493w, ${med32x} 985w, ${med33x} 1478w`} type="image/png"/>
                                    <img src={med31x} alt={'System'}/>
                                </picture>
                            </div>
                            {getUpElement()}
                            {getBlock(block2MedicalData)}
                            {getUpElement()}
                            <div className={'HowItWork-content-picture-mobile-pic fourth'}>
                                <picture>
                                    <source srcSet={`${med41xA} 410w, ${med42xA} 820w, ${med43xA} 1230w`} type="image/avif"/>
                                    <source srcSet={`${med41x} 410w, ${med42x} 820w, ${med43x} 1230w`} type="image/png"/>
                                    <img src={med41x} alt={'Wearable'}/>
                                </picture>
                            </div>
                            {getUpElement()}
                            <div className={'HowItWork-content-picture-mobile-pic fifth'}>
                                <picture>
                                    <source srcSet={`${med51xA} 491w, ${med52xA} 981w, ${med53xA} 1472w`} type="image/avif"/>
                                    <source srcSet={`${med51x} 491w, ${med52x} 981w, ${med53x} 1472w`} type="image/png"/>
                                    <img src={med51x} alt={'Wearable'}/>
                                </picture>
                            </div>
                        </div>
                    </div>
                }
                {switchType === 2 &&
                    <div className={'HowItWork-content-picture'}>
                        <div className={'HowItWork-content-picture-desktop technical'}>
                            <picture>
                                <source srcSet={`${schemeTechnic1xA} 912w, ${schemeTechnic2xA} 1823w, ${schemeTechnic3xA} 2734w`} type="image/avif"/>
                                <source srcSet={`${schemeTechnic1x} 912w, ${schemeTechnic2x} 1823w, ${schemeTechnic3x} 2734w`} type="image/png"/>
                                <img src={schemeTechnic1x} alt={'How it works screen'}/>
                            </picture>
                        </div>
                        <div className={'HowItWork-content-picture-mobile'}>
                            <div className={'HowItWork-content-picture-mobile-pic techFirst'}>
                                <picture>
                                    <source srcSet={`${med21xA} 410w, ${med22xA} 820w, ${med23xA} 1230w`} type="image/avif"/>
                                    <source srcSet={`${med21x} 410w, ${med22x} 820w, ${med23x} 1230w`} type="image/png"/>
                                    <img src={med21x} alt={'Camera'}/>
                                </picture>
                            </div>
                            {getDownElement()}
                            {getBlock(block1TechnicalData)}
                            {getDownElement()}
                            {getBlock(block2TechnicalData)}
                            {getDownElement()}
                            <div className={'HowItWork-content-picture-mobile-pic techSecond'}>
                                <picture>
                                    <source srcSet={`${tech31xA} 521w, ${tech32xA} 1041w, ${tech33xA} 1560w`} type="image/avif"/>
                                    <source srcSet={`${tech31x} 521w, ${tech32x} 1041w, ${tech33x} 1560w`} type="image/png"/>
                                    <img src={tech31x} alt={'Wearable'}/>
                                </picture>
                            </div>
                            {getUpElement()}
                            {getBlock(block3TechnicalData)}
                            {getUpElement()}
                            <div className={'HowItWork-content-picture-mobile-pic techThird'}>
                                <picture>
                                    <source srcSet={`${tech11xA} 410w, ${tech12xA} 820w, ${tech13xA} 1230w`} type="image/avif"/>
                                    <source srcSet={`${tech11x} 410w, ${tech12x} 820w, ${tech13x} 1230w`} type="image/png"/>
                                    <img src={tech11x} alt={'Wearable'}/>
                                </picture>
                            </div>
                            {getUpElement()}
                            <div className={'HowItWork-content-picture-mobile-pic techFourth'}>
                                <picture>
                                    <source srcSet={`${tech21xA} 355w, ${tech22xA} 709w, ${tech23xA} 1063w`} type="image/avif"/>
                                    <source srcSet={`${tech21x} 355w, ${tech22x} 709w, ${tech23x} 1063w`} type="image/png"/>
                                    <img src={tech21x} alt={'Wearable'}/>
                                </picture>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
            </div>
        </div>
    )
}
