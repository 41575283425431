import "./Gallery.scss"
import React, {useRef} from "react";

import pat1x from "../../../images/Gallery/patient.png";
import pat2x from "../../../images/Gallery/patient@2x.png";
import pat3x from "../../../images/Gallery/patient@3x.png";
import pat1xA from "../../../images/Gallery/patient.avif";
import pat2xA from "../../../images/Gallery/patient@2x.avif";
import pat3xA from "../../../images/Gallery/patient@3x.avif";
import patFullA from "../../../images/Gallery/patientFull.avif";
import patFull from "../../../images/Gallery/patientFull.png";

import build1x from "../../../images/Gallery/building.png";
import build2x from "../../../images/Gallery/building@2x.png";
import build3x from "../../../images/Gallery/building@3x.png";
import build1xA from "../../../images/Gallery/building.avif";
import build2xA from "../../../images/Gallery/building@2x.avif";
import build3xA from "../../../images/Gallery/building@3x.avif";
import buildFullA from "../../../images/Gallery/buildingFull.avif";
import buildFull from "../../../images/Gallery/buildingFull.png";

import hw1x from "../../../images/Gallery/hardware.png";
import hw2x from "../../../images/Gallery/hardware@2x.png";
import hw3x from "../../../images/Gallery/hardware@3x.png";
import hw1xA from "../../../images/Gallery/hardware.avif";
import hw2xA from "../../../images/Gallery/hardware@2x.avif";
import hw3xA from "../../../images/Gallery/hardware@3x.avif";
import hwFullA from "../../../images/Gallery/hardwareFull.avif";
import hwFull from "../../../images/Gallery/hardwareFull.png";

import usr1x from "../../../images/Gallery/user.png";
import usr2x from "../../../images/Gallery/user@2x.png";
import usr3x from "../../../images/Gallery/user@3x.png";
import usr1xA from "../../../images/Gallery/user.avif";
import usr2xA from "../../../images/Gallery/user@2x.avif";
import usr3xA from "../../../images/Gallery/user@3x.avif";
import usrFullA from "../../../images/Gallery/userFull.avif";
import usrFull from "../../../images/Gallery/userFull.png";

import detail1x from "../../../images/Gallery/hardwareDetail.png";
import detail2x from "../../../images/Gallery/hardwareDetail@2x.png";
import detail3x from "../../../images/Gallery/hardwareDetail@3x.png";
import detail1xA from "../../../images/Gallery/hardwareDetail.avif";
import detail2xA from "../../../images/Gallery/hardwareDetail@2x.avif";
import detail3xA from "../../../images/Gallery/hardwareDetail@3x.avif";
import detailFullA from "../../../images/Gallery/hardwareDetailFull.avif";
import detailFull from "../../../images/Gallery/hardwareDetailFull.png";

import alarms1x from "../../../images/Gallery/alarms.png";
import alarms2x from "../../../images/Gallery/alarms@2x.png";
import alarms3x from "../../../images/Gallery/alarms@3x.png";
import alarms1xA from "../../../images/Gallery/alarms.avif";
import alarms2xA from "../../../images/Gallery/alarms@2x.avif";
import alarms3xA from "../../../images/Gallery/alarms@3x.avif";
import alarmsFull from "../../../images/Gallery/alarmsFull.png";
import alarmsFullA from "../../../images/Gallery/alarmsFull.avif";

import {Title} from "../../atoms/Title/Title";
import {useOutsideClickTrigger} from "../../models/UIUtil";

export const Gallery = (): React.JSX.Element => {

    const [currentImageView, setCurrentImageView] = React.useState<{ avif: any, png: any }|null>(null);
    const wrapperRef: React.MutableRefObject<null> = useRef(null);
    useOutsideClickTrigger([wrapperRef], () => { handleToggleImageView(null); });

    const items = [
        {i1x: pat1x, i2x: pat2x, i3x: pat3x, i1xA: pat1xA, i2xA: pat2xA, i3xA: pat3xA, iFull: patFull, iFullA: patFullA},
        {i1x: build1x, i2x: build2x, i3x: build3x, i1xA: build1xA, i2xA: build2xA, i3xA: build3xA, iFull: buildFull, iFullA: buildFullA},
        {i1x: hw1x, i2x: hw2x, i3x: hw3x, i1xA: hw1xA, i2xA: hw2xA, i3xA: hw3xA, iFull: hwFull, iFullA: hwFullA},
        {i1x: usr1x, i2x: usr2x, i3x: usr3x, i1xA: usr1xA, i2xA: usr2xA, i3xA: usr3xA, iFull: usrFull, iFullA: usrFullA},
        {i1x: detail1x, i2x: detail2x, i3x: detail3x, i1xA: detail1xA, i2xA: detail2xA, i3xA: detail3xA, iFull: detailFull, iFullA: detailFullA},
        {i1x: alarms1x, i2x: alarms2x, i3x: alarms3x, i1xA: alarms1xA, i2xA: alarms2xA, i3xA: alarms3xA, iFull: alarmsFull, iFullA: alarmsFullA},
    ];

    const handleToggleImageView = (image: { avif: any, png: any }|null) => {

        setCurrentImageView(image ? {avif: image.avif, png: image.png} : null);
        const bodyElement = document.getElementsByTagName('body').item(0)
        if (bodyElement && image !== null) {
            bodyElement.classList.add('disableScroll');
        } else if (bodyElement && image === null) {
            bodyElement.classList.remove('disableScroll');
        }
    }

    return (
        <div id="Gallery" className={'Gallery'}>
            <div className={'Gallery-content'}>
                <div className={'Gallery-content-title'}>
                    <Title text={'Galerie'} accent={'Angelo'} />
                </div>
                <div className={'Gallery-content-items'}>
                    {items.map((item, index: number) => {
                        return (
                            <div
                                onClick={ () => { handleToggleImageView({avif: item.iFullA, png: item.iFull}); } }
                                className={'Gallery-content-items-item'}
                                key={`Gallery-content-items-item-${index}`}>
                                <picture>
                                    <source srcSet={`${item.i1xA} 476w, ${item.i2xA} 952w, ${item.i3xA} 1428w`} type="image/avif"/>
                                    <source srcSet={`${item.i1x} 476w, ${item.i2x} 952w, ${item.i3x} 1428w`} type="image/png"/>
                                    <img src={item.i1x} alt={'Dashboard screen'} />
                                </picture>
                                <div className={'Gallery-content-items-item-hint hint'}>
                                    <div className={'Gallery-content-items-item-hint-inside'}>
                                        <div className={'Gallery-content-items-item-hint-inside-first'}>
                                            Zobrazit
                                        </div>
                                        <div className={'Gallery-content-items-item-hint-inside-second'}>
                                            na celou stranku
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {currentImageView &&
                <div className={`Gallery-view`}>
                        <picture ref={wrapperRef}>
                            <source src={currentImageView.avif} type="image/avif"/>
                            <source src={currentImageView.png} type="image/png"/>
                            <img src={currentImageView.png} alt={'Dashboard screen'}/>
                        </picture>
                </div>
            }
        </div>
    )
}
