import "./Footer.scss"
import React from "react";

export const Footer = (): React.JSX.Element => {

    return (
        <div className={'Footer'}>
            <div className={'Footer-content'}>
                <div className={'Footer-content-inside'}>
                    <div className={'Footer-content-inside-first'}>
                        <div className={'Footer-content-inside-first-text'}>
                            Angelo
                        </div>
                    </div>
                    <div className={'Footer-content-inside-second'}>
                        Copyright 2022. Published by Masterapp
                    </div>
                </div>
            </div>
        </div>
    )
}
